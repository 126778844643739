/*
** @name: Meu Clínicas - loginClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de login (autenticação)
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiUrl } from '@hcpa-react-components/api-request';

import utils from "../../core/utils.js";
import { buildApiRequestInstance } from '../../core/appRequest.js';

const ADMIN_SERVICE_LIST = {
    CONTENT_ADMIN: "CONTENT_ADMIN",
    DASHBOARD: "DASHBOARD",
    EXT_SRV_API: "EXTERNAL_SERVICE_API",
    PE_EXPORT: "EXPORT_PESQUISA_EXPERIENCIA",
    SCC_EXPORT: "EXPORT_SOLICITACAO_AGENDAMENTO_CONSULTA",
    SCC_NOTIFICATION: "NOTIFICATION_SOLICITACAO_REAGENDAMENTO_CONSULTA_CANCELADA"
}

const API_PORT = utils.isDev() ? 8081 : null;
const API_SERVICE = utils.isDev() ? null : 'login-service';
const API_VERSION = 'v2';

class LoginClient {
    #loginApiRequest;

    constructor() {
        this.#loginApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    actuatorInfo(extraAxiosConfig, options) {
        return this.#loginApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/actuator/info'), null, null, extraAxiosConfig, options);
    }

    buildInfo(extraAxiosConfig, options) {
        return this.#loginApiRequest.simple.get(buildApiUrl(process.env.REACT_APP_API_ROOT, API_PORT, API_SERVICE, null, '/build-info'), null, null, extraAxiosConfig, options);
    }

    statistics(jwtServiceToken, fingerprint, extraAxiosConfig, options) {
        const queryParams = { jwtServiceToken, fingerprint };
        return this.#loginApiRequest.noAuth.get('/public/statistics', queryParams, null, extraAxiosConfig, options);
    } 

    async asyncServiceToken(service, username, password, extraAxiosConfig, options) {
        return new Promise(async (resolve, reject) => {
            const uuidv4 = require('uuid/v4');
            const fingerprint = uuidv4();
            var userData = { service, fingerprint, username, password };
            this.#loginApiRequest.noAuth.post('/public/service-token', userData, null, extraAxiosConfig, options)
                .then(res => { 
                    res.data.fingerprint = fingerprint;
                    resolve(res);
                })
                .catch(reject);
        });
    }

    async asyncTokenRefresh(pacCodigo, extendedLifetime, extraAxiosConfig, options) {
        return new Promise(async (resolve, reject) => {
            const queryParams = { 
                pacCodigo,  
                extendedLifetime: extendedLifetime ? true : false
            };
            this.#loginApiRequest.auth.get('/token-refresh', queryParams, null, extraAxiosConfig, options)
                .then(resolve)
                .catch(reject);    
        });
    }

    async asyncUserPermissions(pacCodigo, extraAxiosConfig, options) {
        return this.#loginApiRequest.auth.get(`/permissoes/${pacCodigo}`, null, null, extraAxiosConfig, options);
    }

    logout(token, extraAxiosConfig, options) { 
        return this.#loginApiRequest.auth.get(`/logout/${token}`, null, null, extraAxiosConfig, options);
    }

    login(cpfOuEmail, senha, fingerprint, fromWiFi, extraAxiosConfig, options) {
        const userData = {
            user: cpfOuEmail,
            password: senha,
            fingerprint,
            fromWiFi
        };
        return this.#loginApiRequest.noAuth.post('/public/login', userData, null, extraAxiosConfig, options);
    }

    loginLocalizador(localizadorPaciente, answers, fingerprint, fromWiFi, extraAxiosConfig, options) {
        var userData = { localizadorPaciente, answers, fingerprint, fromWiFi };
        return this.#loginApiRequest.noAuth.post('/public/login/localizador', userData, null, extraAxiosConfig, options);
    }

    loginToken(jwtToken, fingerprint, pacCodigo, extraAxiosConfig, options) {
        var userData = { jwtToken, fingerprint, pacCodigo };
        return this.#loginApiRequest.noAuth.post('/public/login/token', userData, null, extraAxiosConfig, options);
    }

    localizadorPaciente(localizadorPaciente, extraAxiosConfig, options) {
        var userData = { localizadorPaciente };
        return this.#loginApiRequest.noAuth.post('/public/localizador-paciente', userData, null, extraAxiosConfig, options);
    }

    validateLoginToken(jwtToken, fingerprint, extraAxiosConfig, options) {
        var queryParams = { jwtToken, fingerprint };
        return this.#loginApiRequest.noAuth.get('/public/login/token/validate', queryParams, null, extraAxiosConfig, options);
    }

    validateServiceToken(jwtServiceToken, fingerprint, service, extraAxiosConfig, options) {
        var queryParams = { jwtServiceToken, fingerprint, service };
        return this.#loginApiRequest.noAuth.get('/public/service-token/validate', queryParams, null, extraAxiosConfig, options);
    }
} 

const loginClient = new LoginClient();
export default loginClient;
export {
    ADMIN_SERVICE_LIST,
    API_PORT,
    API_SERVICE,
    API_VERSION
};