/*
** @name: Meu Clínicas - cadastro
** @author: 
** @date:
** @description: Módulo para cadastro do usuario.
** 
** @update: Setembro 2020 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para ajustes no layout, dar instruções e integração com localizador
** @update: Março 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Atualizado para novo layout da aplicação e funcionamento com cards
** @update: Maio 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Realizada nova expansão para adicionar confirmação de dados e validar o celular
** @update: Dezembro 2022 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Refatorado compoenntes de renderizacao
*/

import React, { Component } from 'react';
import moment from 'moment';
import { useAppConfigContext, AppCustomMessage } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import logout from '../../core/logout.js';
import sessionStorageManager from '../../core/sessionStorageManager.js';
import { REQUIRED_AGE } from '../../core/specialAccessManager.js';
import { useAuthContext } from '../../core/authContext.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { getAppGeneralSettingsPropertyByName } from '../../core/appSpecificConfigHandler.js';

import AppCardModuleBasicWrapper from '../../components/general/appCardModuleBasicWrapper/appCardModuleBasicWrapper.js';
import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import { loadReCaptcha, ReCaptcha } from '../../components/general/recaptcha/index.js';
import SmsValidationModal from '../../components/general/smsValidationModal/smsValidationModal.js';

import configurationClient from '../../apiClients/login/configurationClient.js';
import usuarioClient from '../../apiClients/login/usuarioClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';

import DataConfirmation from './dataConfirmation.js';
import InstrucoesCadastro from './instrucoesCadastro.js';
import ModalTermos from './modalTermos.js';
import RegisterDone from './registerDone.js';
import RegistrationForm from './registrationForm.js';
import Setup from './setup.js';


// Import module styles
import './scss/cadastro.scss'; 


const CADASTRO_INSTRUCOES = 'CADASTRO-INSTRUCOES';
const ERROR_CLOSE_TIMEOUT = 7000;

const STEPS = {
    SETUP: 'setup',
    INTRUCOES_LOADING: 'instrucoes-loading',
    INTRUCOES: 'instrucoes',
    CADASTRO: 'cadastro',
    CONFIRMACAO: 'confirmacao',
    EFETIVADO: 'efetivado'
}

let autoCloseTimeoutId = null;

const Cadastro = (props) => {
    const authContext = useAuthContext();
    const appControllerContext = useAppControllerContext();
    const appContextConfig = useAppConfigContext().getContextConfig();
    const applyCustomization = useAppConfigContext().applyCustomizationToContextConfig;
    const isCustomized = useAppConfigContext().isCustomized;
    return(
        <CadastroImplem
            authContext={authContext}
            appControllerContext={appControllerContext}
            appContextConfig={appContextConfig}
            applyCustomization={applyCustomization}
            isCustomized={isCustomized}
            {...props}
        />
    )
}

class CadastroImplem extends Component {
    constructor(props) {
        super(props);

        const { localizador } = this.props.appControllerContext.methods.doGetCurrentCardModuleParameters() || {};
        const initialFocus = localizador ? 'nome' : 'localizador';
        this.state = {
            currentStep: STEPS.SETUP,
            instrucoesCadastro: null,
            exibirTermos: null,
            exibirSmsValidation: null,
            registerFormError: null,
            setupError: null,
            informationDialog: null,
            forceUserLogout: false,
            config: {},
            form: {
                fields: {
                    localizador: {
                        value: localizador ? localizador : '',
                        errorMessage: null,
                        disabled: localizador ? true : false
                    },
                },
                fieldToFocus: initialFocus,
                initialFocus: initialFocus,
                passwordValid: false
            },
            smsValidationTokenInfo : { }
        }

        this.recaptchaRef = React.createRef();
    }

    _appSetBackAction(params) {
        const rnIntegration = window.rnIntegration;
        if(rnIntegration && rnIntegration.isAppRunning()) {
            const { termos, smsValidation } = params || {};
            this.props.appControllerContext.methods.doResetAppBackAction();
            if(this.state.currentStep===STEPS.CONFIRMACAO) {
                rnIntegration.backAction.push(() => {
                    this._handleVoltar();
                });
            }
            if(termos) {
                rnIntegration.backAction.push(() => {
                    this._handleTermosClose();
                });
            }
            if(smsValidation) {
                rnIntegration.backAction.push(() => {
                    this._handleValidationClose();
                });
            }
        }
    }

    _clearErrors = () => {
        let form = this.state.form;
        form.fieldToFocus = false;
        for (var prop in form.fields) {
            if (Object.prototype.hasOwnProperty.call(form.fields, prop)) {
                form.fields[prop].errorMessage = null;
            }
        }

        this.setState({ 
            form, 
            registerFormError: null 
        });
    }

    _executeRecaptcha = () => {
        const { config } = this.state;
        this.recaptchaRef.current.execute(config.disableReCaptchaValidation);
    }

    _focusFormField = () => {
        if(!this.state.form.fieldToFocus) {
            return;
        }

        let l_obj = document.getElementsByName(this.state.form.fieldToFocus);
        if(l_obj.length > 0) {
            let obj = l_obj[0];
            obj.focus();

            let form = this.state.form;
            form.fieldToFocus = false;
            this.setState({
                form: form
            });
        } else {
            setTimeout(() => this._focusFormField(), 10);
        }
    }

    _getFormValuesToSubmit = () => {
        const submitValues = {};
        const { form } = this.state;
        const smsValidationTokenInfo = genesysUtils.typeCheck.isObject(this.state.smsValidationTokenInfo) ? this.state.smsValidationTokenInfo : {};

        for (var fn in form.fields) {
            if (Object.prototype.hasOwnProperty.call(form.fields, fn)) {
                const field = form.fields[fn];
                submitValues[fn] = field.value;
            }
        }
        submitValues.dtNascimento = utils.convertDateToEpochAtMidday(form.fields.dtNascimento.value);
        submitValues.dthrAceiteTermos = form.fields.aceiteTermos ? moment().valueOf() : null;
        submitValues.recaptchaToken = this.state.recaptchaToken;
        submitValues.fromWiFi = this.props.appControllerContext.methods.isFromWiFi();

        // Adicionar dados da validacao do celular
        submitValues.smsToken = smsValidationTokenInfo.confirmed ? smsValidationTokenInfo.typedToken : null;
        submitValues.smsTokenId = smsValidationTokenInfo.confirmed ? smsValidationTokenInfo.tokenId : null;

        return submitValues;
    }

    _handleAvancar = () => {
        const updatedForm = this.state.form;
        updatedForm.fieldToFocus = this.state.form.initialFocus;
        this.setState({
            currentStep: STEPS.CADASTRO,
            form: updatedForm
        });
    }

    _handleChange = (fields, { name, value, passwordValid }) => {
        const updatedForm = this.state.form;
        updatedForm.fields = Object.assign({}, fields);
        if(name) {
            updatedForm.fieldToFocus = false;
    
            if(name==='senha') {
                updatedForm.passwordValid = passwordValid;
            }
    
            // Limpa erro do campo de confirmacao quando principal alterado
            if(name==='email') { 
                updatedForm.fields["emailConfirmar"].errorMessage = null;
            }
            if(name==='senha') {
                updatedForm.fields["senhaConfirmar"].errorMessage = null;
            }
        }

        this.setState({ 
            form: updatedForm,
            registerFormError: null,
            smsValidationTokenInfo: (name==='cpf' || name==='numCelular') ? { } : this.state.smsValidationTokenInfo
        });
    }

    _handleClose = () => {
        this.setState({
            setupError: null
        }, () => this.props.appControllerContext.methods.doCardFadeOut());
    }

    _handleConfirmar = () => {
        const { config } = this.state;
        const smsValidationTokenInfo = genesysUtils.typeCheck.isObject(this.state.smsValidationTokenInfo) ? this.state.smsValidationTokenInfo : {};
        if(smsValidationTokenInfo.confirmed || (smsValidationTokenInfo.skipped && !config.requireSmsValidation)) {
            this._executeRecaptcha();
        } else {
            this._handleValidationShow();
        }
    }

    _handleFormAction = () => {
        this._executeRecaptcha();
    }

    _handleRecaptchaError = (err, wasException) => {
        if(wasException) {
            this.setState({
                setupError: { },
                registerFormError: {
                    header: 'Ops...',
                    message: 'Pedimos desculpas. Ocorreu um erro com as configurações recebidas, módulo temporariamente indiponível.'
                }
            });
        } else {
            this.setState({
                registerFormError: {
                    header: 'Ops...',
                    message: 'Ocorreu um erro na validação do reCaptcha.'
                }
            });
        }
    }

    _handleRecaptchaSuccess = (recaptchaToken) => {
        this._clearErrors();
        this.setState({ recaptchaToken });

        const { currentStep, config, smsValidationTokenInfo } = this.state;
        if(currentStep===STEPS.CONFIRMACAO ||
            (!config.showFormConfirmation && smsValidationTokenInfo.confirmed) ||
            (!config.showFormConfirmation && smsValidationTokenInfo.skipped && !config.requireSmsValidation)) {
            setTimeout(() => this._usuarioCadastrar(), 10);
        } else {
            setTimeout(() => this._usuarioValidarDados(), 10);
        }
    }

    _handleTermosClose = () => {
        this.setState({ exibirTermos: false });
        this._appSetBackAction({ termos: false });
    };
    
    _handleTermosShow = () => {
        this.setState({ exibirTermos: true });
        this._appSetBackAction({ termos: true });
    }
    
    _handleValidationClose = (keepTypedToken) => {
        const updateTokenInfo = this.state.smsValidationTokenInfo;
        if(!keepTypedToken) updateTokenInfo.typedToken = null;
        this.setState({ 
            exibirSmsValidation: false,
            smsValidationTokenInfo: updateTokenInfo
        });
        this._appSetBackAction({ smsValidation: false });
    };
    
    _handleValidationShow = () => {
        const { config } = this.state;
        if(!config.requireSmsValidation && config.automaticSkipSmsValidation) {
            this.setState({
                smsValidationTokenInfo: { skipped: true }
            }, () => this._handleValidationTokenComplete());
        } else {
            this.setState({ exibirSmsValidation: true });
            this._appSetBackAction({ smsValidation: true });
        }
    }

    _handleValidationTokenComplete = () => {
        const { config, smsValidationTokenInfo } = this.state;
        if(smsValidationTokenInfo.confirmed || (smsValidationTokenInfo.skipped && !config.requireSmsValidation)) {
            this._handleValidationClose(true);
            this._executeRecaptcha();
        }
    }

    _handleValidationTokenUpdate = (newTokenInfo) => {
        this.setState({
            smsValidationTokenInfo: genesysUtils.typeCheck.isObject(newTokenInfo) ? newTokenInfo : this.state.smsValidationTokenInfo
        })
    }

    _handleVoltar = () => {
        const updateTokenInfo = this.state.smsValidationTokenInfo;
        updateTokenInfo.skipped = null;
        this.setState({ 
            currentStep: STEPS.CADASTRO,
            smsValidationTokenInfo: updateTokenInfo
        });
    }

    _initialize = () => {
        const verifyConfigCustomization = getAppGeneralSettingsPropertyByName(this.props.appContextConfig, "verifyConfigCustomizationOnCadastro");
        if(!verifyConfigCustomization || this.props.isCustomized()) {
            this._loadSetupParameters();
        } else { // customization not applied yet (request before load modules parameters)
            configurationClient.asyncObterParametrosFrontend({}, {})
                .then(res => {
                    if(!res?.data) {
                        throw new Error("[Cadastro] Invalid app configuration response.");
                    }
        
                    // apply custom cofiguration to context configuration and trigger login
                    this.props.applyCustomization(res.data);
                    sessionStorageManager.customConfig.store(res.data);

                    this._loadSetupParameters();
                })
                .catch(err => {
                    this.setState({ 
                        setupError: {
                            header: 'Ops...',
                            message: <AppCustomMessage messageId="_general_sistema-indisponivel" elemType="fragment" />
                        }
                    });
                });
        }
    }

    _loadInstructions = () => {
        if(this.state.instrucoesCadastro) {
            return;
        }

        // Buscar instrucoes de cadastro => userFaq questionId === CADASTRO_INSTRUCOES
        wikiClient.userFaq(CADASTRO_INSTRUCOES, false, null, {}, {})
            .then(res => {
                const result = res.data.listUserFaq;
                const newState = result.constructor===Array && result.length===1 ? STEPS.INTRUCOES : STEPS.CADASTRO;
                this.setState({ 
                    currentStep: newState,
                    instrucoesCadastro: result[0].resposta
                });
            })
            .catch(err => {
                this.setState({ currentStep: STEPS.CADASTRO });
            });
    }
    
    _loadSetupParameters = () => {
        const wifi = this.props.appControllerContext.methods.isFromWiFi();
        configurationClient.obterParametrosCadastro({}, {})
            .then(res => {
                const config = res.data;
                if(wifi) {
                    config.automaticSkipSmsValidation = true;
                    config.showFormConfirmation = false;
                    config.requireSmsValidation = false;
                }
                if(!genesysUtils.typeCheck.isArray(config?.registrationFormConfig?.pages)) {
                    throw new Error("[CONFIGURATION ERROR] Invalid form configuration.");
                }
                if(!config?.disableReCaptchaValidation) {
                    if(!config?.reCaptchaKey) {
                        throw new Error("[CONFIGURATION ERROR] Missing reCaptcha key.");
                    }
                    loadReCaptcha(config.reCaptchaKey);
                }
                this.setState({ 
                    config: config,
                    setupError: null,
                    currentStep: wifi ? STEPS.CADASTRO : STEPS.INTRUCOES_LOADING
                });
            })
            .catch(err => {
                this.setState({ 
                    setupError: {
                        header: 'Ops...',
                        message: 'Pedimos desculpas. Ocorreu um erro obtendo configuração, módulo temporariamente indiponível.'
                    }
                });
                console.error(err);
            });
    }

    _showUnderageMessage = () => {
        const onCancel = () => { this.setState({ informationDialog: null }); };
        const underageMessage = {};
        underageMessage.title = "Atenção";
        underageMessage.message = "No momento o cadastro para menores de idade não é permitido. Pais e responsáveis não devem usar seu CPF no cadastro dos dependentes. Para acessar o sistema o responsável deve acessar diretamente com o localizador.";
        underageMessage.onConfirm = onCancel;
        underageMessage.onCancel = onCancel;

        this.setState({
            informationDialog: underageMessage
        });
    }

    _updateFormErrors = (response) => {
        if(!response || response.status !== 400 || 
            !response.data || !response.data.validatorResponse || !response.data.validatorResponse.errors) {
            return false;
        }

        var hasErros = false;
        var alreadyFocused = false;
        const form = this.state.form;
        const fieldErrors = response.data.validatorResponse.errors;
        for(var fn in form.fields) {
            if(Object.prototype.hasOwnProperty.call(form.fields, fn)) {
                let formField = form.fields[fn];

                if(fieldErrors[fn]) {
                    hasErros = true;
                    formField.errorMessage = fieldErrors[fn];
                    if(!alreadyFocused) {
                      form.fieldToFocus = fn;
                      alreadyFocused = true;
                    }
                } else {
                    formField.errorMessage = null;
                }
            }
        }

        let errMsg = null;
        let updateTokenInfo = this.state.smsValidationTokenInfo;
        if(fieldErrors["smsToken"]) {
            hasErros = true;
            errMsg = fieldErrors["smsToken"];
            updateTokenInfo = {};
        }

        this.setState({ form: form });
        if(hasErros) {
            this.setState({
                currentStep: STEPS.CADASTRO,
                smsValidationTokenInfo: updateTokenInfo,
                registerFormError: {
                    header: "Ops!",
                    message: errMsg ? errMsg : "Verifique o preenchimento dos campos do formulário"
                }
            });
            return true;
        }
        return false;
    }

    _updateGeneralErrors = (response) => {
        if(response && response.status === 429) {
            this.setState({
                currentStep: STEPS.CADASTRO,
                registerFormError: {
                    header: "Ops!",
                    message: "Erro validando reCaptcha."
                }
            });
            return true;
        }
        return false;
    }

    _usuarioCadastrar = () => {
        if(!this._validarIdade()) {
            return;
        }

        usuarioClient.usuarioCadastrar(this._getFormValuesToSubmit(), {}, {})
            .then(res => {
                this.setState({
                    currentStep: STEPS.EFETIVADO,
                    form: this.state.form
                });
            })
            .catch(err => {
                const updateTokenInfo = this.state.smsValidationTokenInfo;
                updateTokenInfo.skipped = null;
                this.setState({ smsValidationTokenInfo: updateTokenInfo });

                if(err.response && err.response.data) {
                    if(this._updateGeneralErrors(err.response)) {
                        return;
                    }

                    if(this._updateFormErrors(err.response)) {
                        return;
                    }
                }

                this.setState({
                    registerFormError: {
                        header: 'Ops...',
                        message: err.response?.status === 406 ? <AppCustomMessage messageId="login_user-beeing-deleted" elemType="fragment" /> : 'Ocorreu um erro ao processar sua requisição'
                    }
                });
            });
    }

    _usuarioValidarDados = () => {
        if(!this._validarIdade()) {
            return;
        }

        usuarioClient.usuarioValidarDados(this._getFormValuesToSubmit(), {}, {})
            .then(res => {
                const { currentStep, config } = this.state;
                if(currentStep===STEPS.CONFIRMACAO || !config.showFormConfirmation) {
                    if(this.state.smsValidationTokenInfo.confirmed) {
                        this._handleValidationTokenComplete();
                    } else {
                        this._handleValidationShow();
                    }
                } else {
                    this.setState({ currentStep: STEPS.CONFIRMACAO });
                }
            })
            .catch(err => {
                if (err.response && err.response.data) {
                    if(this._updateGeneralErrors(err.response)) {
                        return;
                    }
                    if(this._updateFormErrors(err.response)) {
                        return;
                    }
                }

                this.setState({
                    registerFormError: {
                        header: 'Ops...',
                        message: 'Ocorreu um erro ao processar sua requisição'
                    }
                });
            });
    }

    _validarIdade = () => {
        const { dtNascimento } = this.state.form.fields;
        if(!genesysUtils.typeCheck.isObject(dtNascimento)) {
            return null;
        }

        const { value } = dtNascimento;
        const today = moment().startOf('day');
        const age = utils.calculateDateDiff(value, today, 'years');
        const isValidDate = value && utils.isValidDate(value) && !Number.isNaN(age) ? true : false;
        const isUnderage = isValidDate && (age < REQUIRED_AGE);

        if(!isValidDate) {
            const form = this.state.form;
            form.fields.dtNascimento.errorMessage = "Informa uma data correta";
            this.setState({});
            return null;
        }

        if(isUnderage) {
            const form = this.state.form;
            form.fields.dtNascimento.errorMessage = `Não é permitido cadastro para menores de ${REQUIRED_AGE} anos`;
            this.setState({});
            this._showUnderageMessage();
            return false;
        }
        
        return true;
    }

    componentDidMount() {
        this._initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentStep } = this.state;
        if(prevState.currentStep !== currentStep) {
            if(currentStep===STEPS.INTRUCOES_LOADING) {
                this._loadInstructions();
            }

            if(currentStep===STEPS.CONFIRMACAO) {
                utils.scrollAppCardModuleContentTo();
            }

            if(currentStep===STEPS.EFETIVADO) {
                if(this.props.authContext.methods.isAuthenticated()) {
                    this.setState({ forceUserLogout: true });
                }                
            }
    
            this._appSetBackAction({ 
                termos: this.state.exibirTermos,
                smsValidation: this.state.exibirSmsValidation
            });
        }

        if(currentStep===STEPS.CADASTRO) {
            this._focusFormField();
        }

        if(this.state.setupError && !prevState.setupError) {
            autoCloseTimeoutId = setTimeout(() => this._handleClose(), ERROR_CLOSE_TIMEOUT);
        }
    }

    componentWillUnmount() {
        if(this.state.forceUserLogout) {
            logout(null, this.props.authContext, () => { });
        }
        if(autoCloseTimeoutId) {
            clearTimeout(autoCloseTimeoutId);
        }
    }

    render() {
        const { config, exibirTermos, exibirSmsValidation, smsValidationTokenInfo, informationDialog } = this.state;
        const { currentStep, instrucoesCadastro, form, registerFormError, setupError } = this.state;
        const { fields } = form;
        const wifi = this.props.appControllerContext.methods.isFromWiFi();
        return(
            <AppCardModuleBasicWrapper wrapperName="cadastro">

                { informationDialog &&
                <AppConfirmationDialog
                    title={informationDialog.title}
                    message={informationDialog.message}
                    onConfirm={informationDialog.onConfirm}
                    onCancel={informationDialog.onCancel} 
                    hideCancelButton={true} />
                }

                { exibirTermos && <ModalTermos onClose={() => this._handleTermosClose()} /> }

                { exibirSmsValidation && 
                <SmsValidationModal 
                    allowSkip={!config.requireSmsValidation}
                    cpf={fields.cpf.value}
                    numCelular={fields.numCelular.value} 
                    smsActivationToken={smsValidationTokenInfo}
                    onTokenChange={this._handleValidationTokenUpdate}
                    onComplete={this._handleValidationTokenComplete}
                    onCancel={this._handleValidationClose} />
                }

                { config.reCaptchaKey &&
                <ReCaptcha
                    id='recaptcha'
                    ref={this.recaptchaRef}
                    sitekey={config.reCaptchaKey}
                    action='cadastro'
                    verifyCallback={this._handleRecaptchaSuccess}
                    onError={this._handleRecaptchaError}
                />
                }

                { currentStep===STEPS.SETUP && <Setup setupError={setupError} onClose={() => this._handleClose()} /> }
                { currentStep===STEPS.INTRUCOES && <InstrucoesCadastro instrucoesCadastro={instrucoesCadastro} onAvancar={() => { this._handleAvancar() }} /> }
                { currentStep===STEPS.CADASTRO && 
                <RegistrationForm
                    actionButtonTitle={wifi ? "CONFIRMAR" : "PRÓXIMO"}
                    baseFormConfig={config?.registrationFormConfig}
                    fields={fields}
                    formError={registerFormError}
                    hideLocalizador={wifi}
                    onChange={this._handleChange}
                    onShowTermos={this._handleTermosShow}
                    onFormSubmit={this._handleFormAction}
                />
                }
                { currentStep===STEPS.CONFIRMACAO && 
                <DataConfirmation 
                    fields={form.fields}
                    registerError={registerFormError}
                    onConfirmar={() => this._handleConfirmar()}
                    onVoltar={() => this._handleVoltar()} />
                }
                { currentStep===STEPS.EFETIVADO && <RegisterDone onClose={() => this._handleClose()} /> }

            </AppCardModuleBasicWrapper>
        );
    }
}

export default Cadastro;
