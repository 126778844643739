/*
** @name: Meu Clínicas - listDropdown
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Março 2022
** @description: Componente dropdown dom listagem de módulos disponíveis
** 
*/

import React from 'react';
import { Dropdown } from 'semantic-ui-react';


const ListDropdown = (props) => {
    const { fieldData, id, name, className, title, placeHolder, info, options, onChange, disabled, required } = props || {};
    const fieldErrorMessage = fieldData.errorMessage;
    const inputProps = {
        id: id,
        name: name,
        disabled: disabled,
        value: fieldData.value ? fieldData.value : ""
    };
    const dropdownProps = {
        id: `${id}_dd`,
        disabled: disabled,
        className: "field-control" + (fieldErrorMessage ? " mc-border-warning" : "") + (className ? " " + className : "") + (disabled ? " disabled" : ""),
        placeholder: placeHolder,
        options: options || [],
        value: fieldData.value,
        onChange: (_, elem) => onChange({name: name, value: elem.value })
        
    };

    return(
        <div className="field-group dropdown-field">
            <div className="field-label">{title}{required && <i className="field-required" />}{ info && <i className="field-information" title={info} /> }</div>
            <input type="hidden" {...inputProps} />
            <Dropdown selection {...dropdownProps} />
            { fieldErrorMessage &&
            <div className="field-message mc-warning">{fieldErrorMessage}</div>
            }
        </div>
    );
}

export default ListDropdown;