import React, { useState } from 'react';
import Moment from 'react-moment';
import { useAppConfigContext } from '@hcpa-react-components/app-customization';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { getAppGeneralSettingsPropertyByName } from '../../core/appSpecificConfigHandler.js';

import packageJson from '../../../package.json';

const EASTEREGG_SHOW_DELAY = 1000;
const EASTEREGG_CLICK_TIME = 3000;


const VersionInfo = (props) => {
    const [ counter, setCounter ] = useState(0);
    const frontEndVersion = packageJson.version;
    const rnIntegration = window.rnIntegration;
    const isAppRunning = utils.isAppRunning();
    const appVersion = 
        isAppRunning && rnIntegration.getAppVersionInfo && rnIntegration.getAppVersionInfo() ?
            rnIntegration.getAppVersionInfo().version : 
            null;
    const versionInfo = `V ${appVersion ? appVersion + ` (fe ${frontEndVersion})`: frontEndVersion}`;
    const easterEgg = !isAppRunning ? () => { } : () => {
        const newCounter = counter ? (counter+1) : 1;
        if(newCounter>=5) {
            setCounter(0);
            setTimeout(() => { window.rnIntegration.switchAppEnvironment() }, EASTEREGG_SHOW_DELAY);
        } else {
            setCounter(newCounter);
            setTimeout(() => { setCounter(0) } , EASTEREGG_CLICK_TIME);
        }
    };
    const appContextConfig = useAppConfigContext().getContextConfig();
    const showBuildTimeOnProductionEnv = getAppGeneralSettingsPropertyByName(appContextConfig, "showBuildTimeOnProductionEnv");
    const buildTime = utils.buildEpoch();
    const showBuildTime = (genesysUtils.typeCheck.isInteger(buildTime) && (showBuildTimeOnProductionEnv || !utils.isProd()));

    return(
        <div className="versao">
            <div className="no-select" onClick={easterEgg}>
                <span>{versionInfo}</span>
                { showBuildTime &&
                <span className="build-time">({<Moment format="DD/MM/YYYY HH:mm" >{buildTime}</Moment>})</span> }
            </div>
        </div>
    );
}

export default VersionInfo;