/*
** @name: Meu Clínicas - configurationClient
** @author: 
** @date: 
** @description: Rotinas para chamada de API relacionadas ao módulo de login (configurações)
**
** @author: Daniel S. Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: setembro/2024
** @description: Refatorado para utilizar o componente 'api-request'
*/

import { buildApiRequestInstance } from '../../core/appRequest.js';

import { API_PORT, API_SERVICE, API_VERSION } from './loginClient.js';


class ConfigurationClient { 
    #configurationApiRequest;

    constructor() {
        this.#configurationApiRequest = buildApiRequestInstance(process.env.REACT_APP_API_ROOT, API_SERVICE, API_PORT, API_VERSION, {});
    }

    async asyncObterParametrosFrontend(extraAxiosConfig, options) {
        return new Promise(async (resolve, reject) => {
            this.obterParametrosFrontend(extraAxiosConfig, options)
                .then(res => { resolve(res); })
                .catch(err => { reject(err); });
        });
    }

    obterParametrosCadastro(extraAxiosConfig, options) {
        return this.#configurationApiRequest.noAuth.get('/public/parametros-cadastro', null, null, extraAxiosConfig, options);
    }

    obterParametrosFrontend(extraAxiosConfig, options) {
        return this.#configurationApiRequest.noAuth.get('/public/parametros-frontend', null, null, extraAxiosConfig, options);
    }

    obterAdminConfigFormLogin(extraAxiosConfig, options) {
        return this.#configurationApiRequest.noAuth.get('/public/admin/config/login-form', null, null, extraAxiosConfig, options);
    }

    obterAdminConfigFormPesquisaExperiencia(extraAxiosConfig, options) {
        return this.#configurationApiRequest.noAuth.get('/public/admin/config/pesquisa-experiencia', null, null, extraAxiosConfig, options);
    }

    obterAdminConfigFormSolicitacaoAgendamento(extraAxiosConfig, options) {
        return this.#configurationApiRequest.noAuth.get('/public/admin/config/solicitacao-agendamento', null, null, extraAxiosConfig, options);
    }
}

const configurationClient = new ConfigurationClient();
export default configurationClient;