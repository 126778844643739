/*
** @name: Meu Clínicas - dashboard
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2021
** @description: Módulo para acompanhamentos de estatísticas da aplicação
** 
** @update: Abril 2021 - Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @description: Adicionada novas estatísticas e gráficos de acesso
*/

import React, { Component } from 'react';
import FormBuilder from 'react-dj-forms-builder';
import { Form, Accordion } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import utils from '../../core/utils.js';
import { useAppControllerContext } from '../../core/appControllerContext.js';
import { DISABLE_LOADING } from '../../core/appRequest.js';

import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import ExternalServicesSessionLifetime from '../../components/general/externalServicesSessionLifetime/externalServicesSessionLifetime.js';
import { InputField } from '../../components/fields/formsBuilderCustoms';

import configurationClient from '../../apiClients/login/configurationClient.js';
import consultasClient from '../../apiClients/consultas/consultasClient.js';
import examesClient from '../../apiClients/exames/examesClient.js';
import formulariosClient from '../../apiClients/formularios/formulariosClient.js';
import loginClient, { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';
import notificationClient from '../../apiClients/notification/notificationClient.js';
import receitasClient from '../../apiClients/receitas/receitasClient.js';
import registrosMedicosClient from '../../apiClients/registrosMedicos/registrosMedicosClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';

import AccessStatistics from './accessStatistics.js';
import UserStatistics from './userStatistics.js';
import ServicesStatistics from './servicesStatistics.js';
import ServicesInfo from './servicesInfo.js';
import { consultasBuildInfo, examesBuildInfo, formulariosBuildInfo, loginBuildInfo,
        notificationBuildInfo, receitasBuildInfo, regmedicosBuildInfo, wikiBuildInfo } from './buildInfo.js'

import packageJson from '../../../package.json';


// Import module styles
import './dashboard.scss';


const CREDENTIAL_EXPIRING_MS = 1200000;
const SESSION_EXPIRATION_WARNING_SEC = 300;
const MESSAGE_FETCHING_DATA = <div className="fetching-data">Dados ainda sendo coletados. Por favor aguarde ...</div>;

const STEP_INITIALIZE = 'initialize';
const STEP_LOGIN = 'login';
const STEP_SHOW = 'show';

const Dashboard = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <DashboardImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class DashboardImplem extends Component {

    static formConfigLogin = null;

    constructor(props) {
        super(props);

        this.state = this._initialState();
    }

    _carregarDados = () => {
        this._fetchServicesInfo();
        this._fetchServicesStatistics();
    }

    _emptyStatistics = () => {
        const emptyStatistic = {
            users: {
                accesses: {},
            },
            services: {
                consultas: {},
                exames: {},
                formularios: {},
                notification: {},
                login: {},
                receitas: {},
                regmedicos: {},
                wiki: {}
            }
        };

        return emptyStatistic;
    }

    _fetchServicesInfo = async () => {
        // Initialize with WFE info
        const info = { 
            "web-frontend": {
                title: "Frontend - WEB",
                active: false,
                order: 11,
                repoUrl: 'https://git.local.hcpa.ufrgs.br/meuclinicas/web-frontend',
                data: {
                    serviceName: "web-frontend",
                    serviceVersion: packageJson.version,
                    git: {
                        branch: process.env.REACT_APP_CURRENT_BRANCH,
                        commit: {
                            id: process.env.REACT_APP_LAST_COMMIT_HASH,
                            time: process.env.REACT_APP_LAST_COMMIT_DATE_HOUR,
                            message: process.env.REACT_APP_LAST_COMMIT_MESSAGE,
                            user: {
                                email: process.env.REACT_APP_LAST_COMMIT_AUTHOR
                            }
                        }
                    },
                    buildEpoch: utils.buildEpoch()
                }
            }
        }

        // Fetch other services
        this._setLoading(true);
        Promise.all([
            consultasBuildInfo(), examesBuildInfo(), formulariosBuildInfo(), loginBuildInfo(),
            notificationBuildInfo(), receitasBuildInfo(), regmedicosBuildInfo(), wikiBuildInfo()])
            .then(values => values.forEach(v => Object.assign(info, v)))
            .catch(e => console.error('Exception fetching services build information.', e))
            .finally(() => this._setLoading(false));

        // Update build info
        this.setState({ buildInfo: info });
    }

    _fetchServicesStatistics = () => {
        const checkErrorStatus = (err) => {
            if(err.response && err.response.status && err.response.status === 401) {
                this.setState(this._initialState());
            }
        }

        // Initialize statistics data
        this.setState({
            statisticsData: this._emptyStatistics()
        });

        // Fetch statistics data from services
        const credentials = this.state.credentials ? this.state.credentials : {};
        const { jwtServiceToken, fingerprint } = credentials;
        if(!jwtServiceToken || !fingerprint) {
            console.error("Credenciais inválidas.");
            this.setState(this._initialState());
            return;
        }

        consultasClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.consultas.data = res.data["consultas-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            }) 
            .catch(err => {
                console.error("consultasStatistics", err);
                checkErrorStatus(err);
            });

        examesClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.exames.data = res.data["exames-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("examesStatistics", err);
                checkErrorStatus(err);
            });

        formulariosClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.formularios.data = res.data["formularios-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("formulariosStatistics", err);
                checkErrorStatus(err);
            });

        loginClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.login.data = {};
                updStatisticsData.users.accesses.data = res.data["login-service"].acessos;
                updStatisticsData.users.data = res.data["login-service"];
                delete updStatisticsData.users.data.acessos;
                this.setState({
                    statisticsData: updStatisticsData
                });
            }) 
            .catch(err => {
                console.error("loginStatistics", err);
                checkErrorStatus(err);
            });

        notificationClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.notification.data = res.data["notification-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("notificationStatistics", err);
                checkErrorStatus(err);
            });

        receitasClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.receitas.data = res.data["receitas-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("receitasStatistics", err);
                checkErrorStatus(err);
            });

        registrosMedicosClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.regmedicos.data = res.data["regmedicos-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("regmedicosStatistics", err);
                checkErrorStatus(err);
            });

        wikiClient.statistics(jwtServiceToken, fingerprint, {}, DISABLE_LOADING)
            .then(res => {
                const updStatisticsData = this.state.statisticsData;
                updStatisticsData.services.wiki.data = res.data["wiki-service"];
                this.setState({
                    statisticsData: updStatisticsData
                });
            })
            .catch(err => {
                console.error("wikiStatistics", err);
                checkErrorStatus(err);
            });
    }
   
    _focusFormEditField = () => {
        const { currentStep, loginFocus } = this.state;
        if(currentStep===STEP_LOGIN) {
            if(loginFocus) {
                const l_obj = document.getElementsByName(loginFocus);
                if(l_obj.length > 0) {
                    let obj = l_obj[0];
                    obj.focus();
                }    
                this.setState({ loginFocus: false });
            }
        }
    }

    _getCurrentCredentials = () => {
        const { username, password, authenticationTime} = this.state.credentials || {};
        const isCredentialValid = username && password && authenticationTime && ((Date.now() - authenticationTime) <= CREDENTIAL_EXPIRING_MS);
        if(!isCredentialValid) {
            this.setState(this._initialState());
            return [];
        }
        return [username, password];
    }

    async _getServiceTokenAsync(username, password, disableLoading) {
        return new Promise(async (resolve, reject) => {
            loginClient.asyncServiceToken(ADMIN_SERVICE_LIST.DASHBOARD, username, password, {}, (disableLoading ? DISABLE_LOADING : null))
                .then(res => {
                    const result = res.data;
                    if(!result.jwtServiceToken) {
                        this._setErroProcessamento(result.errorMessage ? result.errorMessage : "Ops!, ocorreu um erro validando suas credenciais.");
                        reject(false);
                    }

                    const now = Date.now();
                    const newCredentials = {
                        jwtServiceToken: result.jwtServiceToken,
                        fingerprint: result.fingerprint,
                        username: username,
                        password: password,
                        authenticationTime: now,
                        keepAliveTime: now
                    }

                    this.setState({ credentials: newCredentials }, () => resolve(newCredentials));
                })
                .catch(err => {
                    console.error(">>>", err);
                    this._setErroProcessamento("Ops!, ocorreu um erro validando suas credenciais.");
                    reject(err);
                })
        });
    }

    _handleAccordionClick = (object) => {
        object.active = !object.active;
        this.setState({});
    }

    _handleLoginFormUpdate = (fields) => {
        this.setState({ loginFields: fields });
    }

    _handleLogin = () => {
        const loginFields = this.state.loginFields;
        if(!loginFields) {
            return;
        }

        this._setErroProcessamento(null);

        const username = loginFields.usuario ? loginFields.usuario.value : null;
        const password = loginFields.senha ? loginFields.senha.value : null;
        this._getServiceTokenAsync(username, password, false)
            .then(res => {
                this.setState({
                    currentStep: STEP_SHOW
                });
                this._carregarDados();
            })
            .catch(err => { })
    }

    _handleRefreshData = () => {
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        this._setErroProcessamento(null);
        this._getServiceTokenAsync(username, password, false)
            .then(res => {
                this._carregarDados();
            })
            .catch(err => { })
    }

    _handleSessionExpired = () => {
        setTimeout(() => this.setState(this._initialState()), 3000);
    }

    _handleSessionRefresh = () => {
        const updCrendential = this.state.credentials;
        if(updCrendential) {
            updCrendential.keepAliveTime = Date.now();
            this.setState({ credentials: updCrendential });
        }
    }

    _initializeDashboard = () => {
        configurationClient.obterAdminConfigFormLogin({}, {})
            .then(res => {
                if(res.status !== 200 || !genesysUtils.typeCheck.isObject(res.data)) {
                    this._setErroProcessamento("Ops!, as configurações recebidas para o dashboard estão com erro e não será possível continuar. Por favor tente novamente mais tarde.");
                    return;
                }
                this.formConfigLogin = { ...res.data };
                this.setState({ currentStep: STEP_LOGIN });
            })
            .catch(err => {
                console.error(">>>", err);
                const errorMessage = err?.response?.data?.errorMessage;
                this._setErroProcessamento(<p>Ops!, pedimos desculpas, mas ocorreu um erro obtendo configurações do dashboard.{errorMessage ? ` [${errorMessage}]` : ''}<br/>Por favor tente novamente mais tarde.</p>);
            })
    }

    _initialState = () => {
        return {
            currentStep: this.formConfigLogin ? STEP_LOGIN : STEP_INITIALIZE,
            loginFields: null,
            loginFocus: 'usuario',
            erroProcessamento: null,
            buildInfo: {},
            statisticsData: this._emptyStatistics(),
            credentials: null
        };
    }

    _setErroProcessamento = (msg) => {
        this.setState({ erroProcessamento: msg });
    }

    _setLoading = (visible) => {
        utils.setLoadingVisibility(visible, this.props.appControllerContext);
    }

    componentDidMount() {
        this._initializeDashboard();
    }

    componentDidUpdate() {
        this._focusFormEditField();        
    }

    render() {
        const { currentStep, credentials, loginFields, erroProcessamento } = this.state;
        const loginEnabled = loginFields && loginFields.usuario && loginFields.usuario.value && loginFields.senha && loginFields.senha.value;
        return (
            <div className="dashboard-wrapper">
                <AppExtraDocumentHead subTitle="Dashboard" robots="noindex,nofollow" />

                <div className="content">
                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1>Dashboard</h1>
                        </AppExternalServiceHeader>
                    </div>

                    { currentStep===STEP_INITIALIZE &&
                    <AppExternalServiceInfoMessage id="msgInitDashErrorID" className="info-error">
                        {erroProcessamento}
                    </AppExternalServiceInfoMessage>
                    }

                    { currentStep===STEP_LOGIN &&
                    <div className="login-section">
                        <Form name="formMain">
                            <div className="form-wrapper">
                                <FormBuilder 
                                    config={this.formConfigLogin}
                                    fields={loginFields}
                                    page={0}
                                    className="form-login" 
                                    onChange={this._handleLoginFormUpdate}
                                    overrideFieldRender={{
                                        'input': InputField
                                    }}
                                />
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button 
                                    disabled={!loginEnabled} type="default" 
                                    className="btn-login" onClick={() => this._handleLogin()}>Login</button>
                            </div>
                        </Form>
                    </div>
                    }

                    { currentStep===STEP_SHOW &&
                    <div className="content-section">

                        <ExternalServicesSessionLifetime
                            sessionStartTime={credentials ? credentials.keepAliveTime : null}
                            onExpire={() => this._handleSessionExpired()}
                            onRefresh={() => this._handleSessionRefresh()}
                            sessionExpireTimeMs={CREDENTIAL_EXPIRING_MS}
                            expirationWarningSec={SESSION_EXPIRATION_WARNING_SEC}
                        />

                        <div className="section-header-wrapper">
                            <h2>Estatísticas de Uso:</h2>
                            <div className="refresh-wrapper">
                                <button type="default" className="btn-refresh" onClick={() => this._handleRefreshData()}>Atualizar dados</button>
                            </div>
                        </div>

                        <div className="statistics-info-data">
                            <Accordion fluid styled>
                                <UserStatistics data={this.state.statisticsData?.users} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />

                                <AccessStatistics data={this.state.statisticsData?.users?.accesses} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />

                                <ServicesStatistics data={this.state.statisticsData?.services} fetchingMessage={MESSAGE_FETCHING_DATA} onToggle={this._handleAccordionClick} />
                            </Accordion>
                        </div>

                        <div className="section-header-wrapper">
                            <h2>Informações do Serviços:</h2>
                        </div>
                        <div className="build-info-data">
                            <ServicesInfo buildInfo={this.state.buildInfo} onToggle={this._handleAccordionClick} />
                        </div>

                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default Dashboard;