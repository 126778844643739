/*
** @name: Meu Clínicas - newsManager
** @author: Daniel da Silva Jegorschki Santos (djsantos@hcpa.edu.br)
** @date: Fevereiro 2022
** @description: Módulo para gerenciamento das notícias
** 
*/

import React, { Component } from 'react';
import FormBuilder from 'react-dj-forms-builder';
import { Form } from 'semantic-ui-react';
import { genesysUtils } from '@hcpa-react-components/genesys-utils';

import { useAppControllerContext } from '../../core/appControllerContext.js';

import AppConfirmationDialog from '../../components/general/appConfirmationDialog/appConfirmationDialog.js';
import AppExternalServiceHeader from '../../components/general/appExternalServiceHeader/appExternalServiceHeader.js';
import AppExternalServiceInfoMessage from '../../components/general/appExternalServiceInfoMessage/appExternalServiceInfoMessage.js';
import AppExtraDocumentHead from '../../components/general/appExtraDocumentHead/appExtraDocumentHead.js';
import ExternalServicesSessionLifetime from '../../components/general/externalServicesSessionLifetime/externalServicesSessionLifetime.js';
import { InputField } from '../../components/fields/formsBuilderCustoms';

import { isValidImageType } from '../../components/general/imageCarousel/imageCarousel.js';

import NewsEditList from './newsEditList.js';
import NewsEditModal from './newsEditModal.js';

import configurationClient from '../../apiClients/login/configurationClient.js';
import loginClient, { ADMIN_SERVICE_LIST } from '../../apiClients/login/loginClient.js';
import wikiClient from '../../apiClients/wiki/wikiClient.js';


// Import module styles
import './scss/newsManager.scss';


const CREDENTIAL_EXPIRING_MS = 1200000;
const SESSION_EXPIRATION_WARNING_SEC = 300;
const ERROR_MESSAGE_CREDENTIAL = "Ops!, ocorreu um erro validando suas credenciais.";
const IMAGE_MAX_SIZE = 2048 * 1024; // 2 Mebibytes
const IMAGE_MAX_DELTA_ASPECT_RATIO = 0.005; // 0,5%

const STEPS = {
    LOGIN: 'login',
    EDIT: 'edit',
    INITIALIZE: 'initialize'
}


const NewsManager = (props) => {
    const appControllerContext = useAppControllerContext();
    return(
        <NewsManagerImplem
            appControllerContext={appControllerContext}
            {...props}
        />
    )
}

class NewsManagerImplem extends Component {
    static formConfigLogin = null;

    constructor(props) {
        super(props);

        this.state = this._initialState();

    }

    _buildComparingList = (list) => {
        const result = [];
        if(genesysUtils.typeCheck.isArray(list)) {
            list.forEach(item => {
                if(item.id) {
                    result[item.id] = { ...item };
                }
            });
        }
        return result;
    }

    _buildListChangesToSave = (items) => { // Build a lista of all changed items with list editable fields
        const changes = [];
        if(genesysUtils.typeCheck.isArray(items)) {
            items.forEach(item => {
                if(this._checkItemChanged(item, ["ordem", "visivel"])) {
                    changes.push({
                        id: item.id,
                        ordem: item.ordem,
                        visivel: item.visivel
                    });
                }
            });
        }

        return changes;
    }

    _buildEditItemData = (item) => {
        const itemData = genesysUtils.typeCheck.isObject(item) ? JSON.parse(JSON.stringify(item)) : null;
        if(itemData && itemData.id) {
            if(!this.state.originalNewsValues[itemData.id]) {
                alert('Item inválido para edição');
                return;
            }
            
            // restore original 'ordem' value when editing a existing item
            itemData.ordem = this.state.originalNewsValues[itemData.id].ordem;
        }
        return itemData;
    }

    _buildNewsItemToSave = (srcData) => { // Build item object to save with only ediable fields
        const result = {};
        result.id = srcData.id ? srcData.id : null;
        result.ordem = srcData.ordem;
        result.visivel = srcData.visivel ? true : false;
        result.exhibitLocation = srcData.exhibitLocation;
        result.imagens = [];
        if(genesysUtils.typeCheck.isArray(srcData.imagens)) {
            srcData.imagens.forEach(img => {
                if(!img.deleted) {
                    const imgData = {
                        legenda: img.legenda,
                        link: img.link,
                        linkTarget: null,
                        openModule: img.openModule,
                        paramModule: (img.openModule && (img.paramModule || "").trim()) ? JSON.stringify(JSON.parse(img.paramModule)) : null,
                        imageBase64: img.imageBase64
                    };
                    result.imagens.push(imgData);
                }
            });
        }
        return result;
    }

    _carregarDados = () => {
        this._handleSessionRefresh();
        this._getNewsItems();
    }

    _checkItemChanged = (item, fields, compareTo, imageIndex) => {
        const original = compareTo || this.state.originalNewsValues[item.id];
        const emptyAsNull = (val) => val ? val : null;
        const shouldCheck = (name) => (!fields || fields.includes(name));
        const checkImagesChanged = () => {
            return (!item.imagens && original.imagens) ||
                    item.imagens.find((imgData, indx) => {
                        const comp = original.imagens ? original.imagens[indx] : null;
                        return (imageIndex===undefined || indx===imageIndex) &&
                                (!comp || 
                                    imgData.deleted ||
                                    emptyAsNull(imgData.link) !== emptyAsNull(comp.link) || 
                                    emptyAsNull(imgData.legenda) !== emptyAsNull(comp.legenda) ||
                                    emptyAsNull(imgData.linkTarget) !== emptyAsNull(comp.linkTarget) || 
                                    emptyAsNull(imgData.openModule) !== emptyAsNull(comp.openModule) ||
                                    emptyAsNull(imgData.paramModule) !== emptyAsNull(comp.paramModule) ||
                                    emptyAsNull(imgData.imageBase64) !== emptyAsNull(comp.imageBase64));
                    });
        }
        if(item && original) {
            // compare editable values - 'criadoEm' and 'atualizadoEm' are controled only in BE
            return (shouldCheck("id") && !item.id) ||
                (shouldCheck("imagens") && checkImagesChanged()) ||
                (shouldCheck("ordem") && item.ordem !== original.ordem) ||
                (shouldCheck("exhibitLocation") && item.exhibitLocation !== original.exhibitLocation) ||
                (shouldCheck("visivel") && item.visivel !== original.visivel);
        }
        return true;
    }

    _checkNewsListChanges = (news) => {
        return news && news.find(item => this._checkItemChanged(item)) ? true : false;
    }

    _clearFormEditErros = () => {
        const updEditItem = this.state.newsEditItem;
        if(updEditItem) {
            updEditItem.control.erroEdicao = null;
            this.setState({ newsEditItem: updEditItem });
        }
    }

    _focusFormEditField = () => {
        const { loginFocus, currentStep } = this.state;
        if(currentStep === STEPS.LOGIN) {
            if(loginFocus) {
                const arrObj = document.getElementsByName(loginFocus);
                if(arrObj.length > 0) {
                    arrObj[0].focus();
                }    
                this.setState({ loginFocus: false });
            }
        }
    }

    _getCurrentCredentials = () => {
        const { username, password, authenticationTime} = this.state.credentials || {};
        const isCredentialValid = username && password && authenticationTime && ((Date.now() - authenticationTime) <= CREDENTIAL_EXPIRING_MS);
        if(!isCredentialValid) {
            this.setState(this._initialState());
            return [];
        }
        return [username, password];
    }

    _getNewsItems = (listTags) => {
        // Buscar lista de noticias
        this._setErroProcessamento(null);
        wikiClient.newsGetAll({}, {})
            .then(res => {              
                const newsItems = res.data.newsList;
                const originalValues = this._buildComparingList(newsItems);
                const sortedItems = this._sortByOrdem(newsItems);
                sortedItems.forEach((item, indx) => { item.ordem = indx; }); // Atualiza ordem efetiva (ordem => array index)
                sortedItems.forEach(item => { // Verifica se imagens são de tipos válidos para exibir
                    if(!this._validateItemImageType(item.imagens)) {
                        item.visivel = false;
                    }
                });
                this.setState({
                    newsItems: sortedItems,
                    originalNewsValues: originalValues
                });
            })
            .catch(err => {
                const { loginFields } = this.state;
                const newState = this._initialState();
                newState.loginFields = { ...loginFields };
                this.setState(newState, () => this._setErroProcessamento("Ops!, ocorreu um erro obtendo lista de notícias."));
            });
    }

    async _getServiceTokenAsync(username, password) {
        return new Promise(async (resolve, reject) => {
            loginClient.asyncServiceToken(ADMIN_SERVICE_LIST.CONTENT_ADMIN, username, password, {}, {})
            .then(res => {
                const resData = res.data || {};
                if(!resData.jwtServiceToken) {
                    this._setErroProcessamento(resData.errorMessage ? resData.errorMessage : ERROR_MESSAGE_CREDENTIAL);
                    reject(false);
                }
                const now = Date.now();
                const newCreds = {
                    authenticationTime: now,
                    keepAliveTime: now,
                    fingerprint: resData.fingerprint,
                    jwtServiceToken: resData.jwtServiceToken,
                    username: username,
                    password: password
                }

                this.setState({ credentials: newCreds }, () => resolve(newCreds));
            })
            .catch(err => {
                this._setErroProcessamento(ERROR_MESSAGE_CREDENTIAL);
                reject(err);
            })
        });
    }

    _goToLoginWithErrorMessage = (message) => {
        const newState = this._initialState();
        newState.erroProcessamento = message;
        this.setState(newState);
    }

    _handleLogin = () => {
        const { loginFields } = this.state;
        if(!genesysUtils.typeCheck.isObject(loginFields)) {
            return;
        }

        this._setErroProcessamento(null);
        const username = loginFields.usuario ? loginFields.usuario.value : null;
        const password = loginFields.senha ? loginFields.senha.value : null;
        this._getServiceTokenAsync(username, password)
            .then(res => this.setState({ currentStep: STEPS.EDIT }, () => this._carregarDados()))
            .catch(err => {})
    }

    _handleLoginFormUpdate = (fields) => {
        this.setState({ loginFields: fields });
    }

    _handleNewsDelete = (item) => {
        const { newsItems } = this.state;
        const hasChanges = this._checkNewsListChanges(newsItems);
        const doCancel = () => this.setState({ confirmationData: null });
        const doDelete = () => this.setState({ confirmationData: null }, () => this._newsItemDelete(item));
        this.setState({
            confirmationData: {
                hideCancelButton: false,
                title: "Atenção",
                message: hasChanges ? 
                        "As alterações de registros não foram salvas e serão perdidas. Confirma a exclusão?":
                        "Confirma a exclusão do item?",
                onConfirm: doDelete,
                onCancel: doCancel
            }
        });
    }

    _handleNewsEdit = (item, index, changes) => {
        const doCancel = () => this.setState({ confirmationData: null });
        const doEdit = () => this._handleNewsEditShow(item, index);
        const hasChanges = changes===false ? false : (changes || this._checkNewsListChanges(this.state.newsItems));
        if(hasChanges) {
            this.setState({
                confirmationData: {
                    hideCancelButton: false,
                    title: "Atenção",
                    message: "As alterações de registros não foram salvas e poderão ser perdidas. Confirma?",
                    onConfirm: () => { doCancel(); doEdit(); },
                    onCancel: doCancel
                }
            });
        } else {
            doEdit();
        }
    }

    _handleNewsEditClose = (changed) => {
        const doCancel = () => this.setState({ confirmationData: null });
        const doClose = () => this.setState({ newsEditItem: null, confirmationData: null });
        if(changed) {
            this.setState({
                confirmationData: {
                    hideCancelButton: false,
                    title: "Atenção",
                    message: "Foram realizadas alterações nesse item. Deseja mesmo descartá-las?",
                    onConfirm: doClose,
                    onCancel: doCancel
                }
            });
        } else {
            doClose();
        }
    }

    _handleNewsEditImageItemAdd = () => {
        const updEditItem = this.state.newsEditItem;
        const newImageItem = {
            isNew: true,
            legenda: null,
            link: null,
            linkTarget: null,
            openModule: null,
            paramModule: null,
            imageBase64: null
        }
        if(!genesysUtils.typeCheck.isArray(updEditItem.data.imagens)) {
            updEditItem.data.imagens = [];
        }
        updEditItem.data.imagens.push(newImageItem);
        this.setState({ newsEditItem: updEditItem });
    }

    _handleNewsEditImageItemChange = (imgIndex, fileBlob, imgBase64) => {
        const updEditItem = this.state.newsEditItem;
        const imgData = updEditItem.data.imagens[imgIndex];
        if(imgData && fileBlob && imgBase64) {
            if(fileBlob.size > IMAGE_MAX_SIZE) {
                updEditItem.control.fields.imagens.errorMessage[imgIndex] = `Arquivo excede o limite de ${IMAGE_MAX_SIZE} bytes`;
            } else if(!this._updateImageError({ imageBase64: imgBase64 }, updEditItem.control, imgIndex)) {
                imgData.imageBase64 = imgBase64;
                updEditItem.control.erroEdicao = null;
            }

            this.setState({ newsEditItem: updEditItem });
        }
    }

    _handleNewsEditImageItemDeleteSet = (imgIndex, deleted) => {
        const updEditItem = this.state.newsEditItem;
        if(updEditItem.data.imagens[imgIndex]) {
            updEditItem.control.erroEdicao = null;
            this._updateImageError(updEditItem.data.imagens[imgIndex], updEditItem.control, imgIndex);
            if(updEditItem.data.imagens[imgIndex].isNew) {
                updEditItem.data.imagens.splice(imgIndex, 1);
                Object.keys(updEditItem.control.fields).forEach(key => {
                    const ctrlFld = updEditItem.control.fields[key];
                    if(genesysUtils.typeCheck.isArray(ctrlFld.errorMessage)) {
                        ctrlFld.errorMessage.splice(imgIndex, 1);
                    }
                });
            } else { // deleção virtual
                updEditItem.data.imagens[imgIndex].deleted = deleted;
            }
            this.setState({ newsEditItem: updEditItem });
        }
    }

    _handleNewsEditImageItemFormChange = ({index, name, value}) => {
        const updEditItem = this.state.newsEditItem;
        const imgFldUpd = updEditItem.control.fields[name];
        if (!imgFldUpd || !updEditItem.data.imagens || (value && imgFldUpd.regex && !imgFldUpd.regex.test(value))) {
            return;
        }

        if(updEditItem.data.imagens[index] !== undefined) {
            // atualiza campos relacionados
            if(value!==updEditItem.data.imagens[index][name]) {
                if(name==="openModule") {
                    updEditItem.data.imagens[index]["link"] = "";
                } else if(name==="link") {
                    updEditItem.data.imagens[index]["openModule"] = "";
                }
            }

            // atualiza campo alterado
            imgFldUpd.errorMessage[index] = null;
            updEditItem.control.erroEdicao = null;
            updEditItem.data.imagens[index][name] = value;

            this.setState({ newsEditItem: updEditItem });
        }
    }

    _handleNewsEditImageOrderSwap = (indxA, indxB) => {
        const updEditItem = this.state.newsEditItem;
        const imgDataA = updEditItem.data.imagens[indxA];
        const imgDataB = updEditItem.data.imagens[indxB];
        updEditItem.data.imagens[indxA] = imgDataB;
        updEditItem.data.imagens[indxB] = imgDataA;

        const imgCtrlA = updEditItem.control.fields.imagens.errorMessage[indxA];
        const imgCtrlB = updEditItem.control.fields.imagens.errorMessage[indxB];
        updEditItem.control.fields.imagens.errorMessage[indxA] = imgCtrlB ? imgCtrlB : null;
        updEditItem.control.fields.imagens.errorMessage[indxB] = imgCtrlA ? imgCtrlA : null;

        this.setState({ newsEditItem: updEditItem });
    }

    _handleNewsEditLocationChange = (value) => {
        const updEditItem = this.state.newsEditItem;

        // atualiza campo alterado
        updEditItem.data.exhibitLocation = value;
        updEditItem.control.fields.exhibitLocation.errorMessage = null;
        this.setState({ newsEditItem: updEditItem });
    }

    _handleNewsEditOk = (listImgElems) => {
        if(!this._validateItemToSave(listImgElems)) {
            return;
        }

        const { newsEditItem } = this.state;
        this._newsItemSave(this._buildNewsItemToSave(newsEditItem.data));
    }

    _handleNewsEditShow = (item, index) => {
        const itemData = this._buildEditItemData(item);
        if(itemData) {
            const control = this._initialEditFormControl();
            itemData.imagens.forEach((img, indx) => this._updateImageError(img, control, indx));
            this.setState({
                newsEditItem: {
                    data: itemData,
                    control
                },
            }, () => this._handleSessionRefresh());
        }
    }

    _handleNewsEditToggleVisibility = () => {
        const updEditItem = this.state.newsEditItem;
        updEditItem.control.erroEdicao = null;
        updEditItem.data.visivel = !updEditItem.data.visivel;
        this.setState({ newsEditItem: updEditItem });
    }

    _handleNewsInsert = (position) => {
        const newItem = {
            id: null,
            imagens: [],
            ordem: position,
            visivel: true
        }
        this._handleNewsEdit(newItem, null);
    }

    _handleNewsOrderChange = (index, newOrder) => {
        let updNewsList = this.state.newsItems;
        const currOrder = updNewsList[index].ordem;
        if(currOrder !== newOrder) {
            updNewsList[index].ordem = newOrder + (newOrder > currOrder ? 0.5 : -0.5);
            updNewsList = this._sortByOrdem(updNewsList);
            updNewsList.forEach((item, indx) => item.ordem = indx);
            this.setState({
                newsItems: updNewsList
            });
        }
    }
    
    _handleNewsOrderSwap = (indxA, indxB) => {
        const { newsItems } = this.state;
        const newOrderA = newsItems[indxB].ordem;
        const newOrderB = newsItems[indxA].ordem;
        newsItems[indxA].ordem = newOrderA;
        newsItems[indxB].ordem = newOrderB;
        this.setState({ 
            newsItems: this._sortByOrdem(newsItems)
        });
    }

    _handleNewsToggleVisibility = (index) => {
        const updNews = this.state.newsItems;
        updNews[index].visivel = !updNews[index].visivel;
        this.setState({ newsItems: updNews });
    }

    _handleSave = () => {
        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e processar alteracoes
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;
                const listNewsUpdate = this._buildListChangesToSave(this.state.newsItems);

                wikiClient.newsBatchUpdate(jwtServiceToken, fingerprint, listNewsUpdate, {}, {})
                    .then(res => {
                        const response = res.data;
                        if(response.sucesso) {
                            this._setErroProcessamento(null);

                            // show success message and reload items
                            const onClose = () => this.setState({ confirmationData: null }, () => this._carregarDados());
                            this.setState({
                                confirmationData: {
                                    title: "Sucesso",
                                    message: "As alterações foram salvas com sucesso.",
                                    hideCancelButton: true,
                                    onConfirm: onClose,
                                    onCancel: onClose
                                }
                            });

                        } else {
                            const validator = response.validatorResponse || {};
                            this._setErroProcessamento(validator.errors && validator.errors.erroGeral ? 
                                validator.errors.erroGeral : "Ops!, ocorreu um erro inesperado como resposta para sua solicitação.");
                        }
        
                    })
                    .catch(err => this._setErroProcessamento("Ops!, ocorreu um erro processando sua solicitação."));
            })
            .catch(err => {
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.erroProcessamento);
                }
            });
    }

    _handleSessionExpired = () => {
        setTimeout(() => this.setState(this._initialState()), 3000);
    }

    _handleSessionRefresh = () => {
        const updCrendential = { ...this.state.credentials };
        if(updCrendential) {
            updCrendential.keepAliveTime = Date.now();
            this.setState({ credentials: updCrendential });
        }
    }

    _initialEditFormControl = () => {
        return {
            erroEdicao: null,
            fields: {
                exhibitLocation: {
                    errorMessage: null
                },
                imagens: {
                    errorMessage: []
                },
                legenda: {
                    regex: /^[ !'"()\-/=?@[\]_0-9a-zà-ýA-ZÀ-Ý]{1,40}$/,
                    errorMessage: []
                },
                link: {
                    regex: /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;%=]{1,2048}$/,
                    errorMessage: []
                },
                openModule: {
                    errorMessage: []
                },
                paramModule: {
                    regex: /^[!-;?-~à-úA-ZÀ-Ú '\n]{1,1000}$/,
                    errorMessage: []
                }
            }
        }
    }

    _initializeNewsManager = () => {
        configurationClient.obterAdminConfigFormLogin({}, {})
            .then(result => {
                if(result.status === 200 && genesysUtils.typeCheck.isObject(result.data)) {
                    this.formConfigLogin = Object.assign({}, result.data);
                    this.setState({ currentStep: STEPS.LOGIN });
                    return;
                }
                this._setErroProcessamento("Ops!, as configurações recebidas estão com erro e não será possível continuar com o módulo de gerenciamento de notícias. Por favor tente novamente mais tarde.");
            })
            .catch(error => {
                console.error(">>>", error);
                const { errorMessage } = error?.response?.data || {};
                this._setErroProcessamento(<p>Ops!, pedimos desculpas, mas ocorreu um erro obtendo configurações do módulo de gerenciamento de notícias.{errorMessage ? ` [${errorMessage}]` : ''}<br/>Por favor tente novamente mais tarde.</p>);
            })
    }

    _initialState = () => {
        return {
            currentStep: !this.formConfigLogin ? STEPS.INITIALIZE : STEPS.LOGIN,
            loginFields: null,
            loginFocus: 'usuario',
            erroProcessamento: null,
            confirmationData: null,
            credentials: null,
            newsItems: null,
            newsEditItem: null,
            originalNewsValues: []
        };
    }

    _newsItemDelete = (item) => {
        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e deleta item
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;

                // Delete news record
                wikiClient.newsDelete(jwtServiceToken, fingerprint, item.id, {}, {})
                    .then(res => {
                        // show success message and reload items
                        const onClose = () => this.setState({ confirmationData: null }, () => this._carregarDados());
                        this.setState({
                            confirmationData: {
                                title: "Sucesso",
                                message: "O registro foi deletado com sucesso.",
                                hideCancelButton: true,
                                onConfirm: onClose,
                                onCancel: onClose
                            }
                        });
                    })
                    .catch(err => {
                        if(err.response.status===403) {
                            this._goToLoginWithErrorMessage(ERROR_MESSAGE_CREDENTIAL);
                            return;
                        } 
                        const errMsg = (err.response.status === 410) ? "Ops!, registro não localizado." : "Ops!, ocorreu um erro processando sua solicitação.";
                        this._setErroProcessamento(errMsg);
                    });
            })
            .catch(err => {
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.erroProcessamento);
                }
            });
    }

    _newsItemSave = (item) => {
        this._clearFormEditErros();

        // Obter credenciais atuais
        const [ username, password ] = this._getCurrentCredentials();
        if(!username || !password) {
            return;
        }

        // Obter token de servico e salvar item
        this._getServiceTokenAsync(username, password)
            .then(credentials => {
                const { jwtServiceToken, fingerprint } = credentials;

                // Save news record
                wikiClient.newsSave(jwtServiceToken, fingerprint, item, {}, {})
                    .then(res => {
                        const result = res.data;
                        if(result.sucesso) {
                            if(!result.item) {
                                const newState = this._initialState();
                                newState.erroProcessamento = "Ops, erro fatal - Retorno inválido para requisição";
                                this.setState(newState);
                                return;
                            }
                            this._setErroProcessamento(null);

                            // show success message and update edit item to reflet changes
                            const confirmationData = {
                                hideCancelButton: true,
                                title: "Sucesso",
                            }
                            let onClose, onStateReady;
                            if(!item.id) { // insert
                                onStateReady = () => {};
                                confirmationData.message = "Novo item incluido com sucesso.";
                            } else { // update
                                onStateReady = () => this._handleNewsEditShow(result.item);
                                confirmationData.message = "As alterações foram salvas com sucesso.";
                            }
                            onClose = () => this.setState({ confirmationData: null, newsEditItem: null }, this._carregarDados());
                            confirmationData.onConfirm = onClose;
                            confirmationData.onCancel = onClose;
                            this.setState({ confirmationData }, onStateReady);
                        } else {
                            this._setErroProcessamento(result.erroGeral ? result.erroGeral : "Por favor, verifique o correto preenchimento do formulário.");
                            this._processaErrosGeraisFormulario(result.validatorGeneralFields);
                            this._processaErrosImagensFormulario(result.validatorImagens);
                        }
                    })
                    .catch(err => {
                        this._setErroProcessamento("Ops!, ocorreu um erro processando sua solicitação.");
                    });
            })
            .catch(err => {
                if(err===false) {
                    this._goToLoginWithErrorMessage(this.state.newsEditItem.control.erroEdicao);
                }
            });
    }

    _processaErrosGeraisFormulario = (validator) => {
        const updEditItem = this.state.newsEditItem;
        if(updEditItem && updEditItem.data && updEditItem.control && updEditItem.control.fields
                && genesysUtils.typeCheck.isObject(validator) && !validator.success) {
            const { errors } = validator;
            Object.keys(errors).forEach(fld => {
                updEditItem.control.fields[fld].errorMessage = errors[fld];
            });
            
            this.setState({ newsEditItem: updEditItem });
        }
    }

    _processaErrosImagensFormulario = (validator) => {
        const updEditItem = this.state.newsEditItem;
        if(updEditItem && updEditItem.data && updEditItem.control && genesysUtils.typeCheck.isArray(validator)) {
            updEditItem.data.imagens.forEach((img, indx) => { // add a success validation for deleted images
                if(img.deleted) {
                    validator.splice(indx, 0, { success: true });
                }
            });

            validator.forEach((vitem, indx) => {
                if(!vitem.success && genesysUtils.typeCheck.isObject(vitem.errors) && indx < updEditItem.data.imagens.length) {
                    Object.keys(vitem.errors).forEach(key => {
                        const ctrlFld = updEditItem.control.fields[key];
                        if(genesysUtils.typeCheck.isObject(ctrlFld)) {
                            ctrlFld.errorMessage[indx] = vitem.errors[key];
                        }
                    });
                }
            });
        }
        this.setState({ newsEditItem: updEditItem });
    }

    _setErroProcessamento = (msg) => {
        const updEditItem = this.state.newsEditItem;
        if(updEditItem && updEditItem.control) {
            updEditItem.control.erroEdicao = msg;
            this.setState({ newsEditItem: updEditItem });
        } else {
            this.setState({ erroProcessamento: msg });
        }
    }

    _sortByOrdem = (news) => {
        if(!genesysUtils.typeCheck.isArray(news)) {
            return [];
        }
        return news.sort((a, b) => a.ordem < b.ordem ? -1 : (a.ordem > b.ordem ? 1 : 0));
    }
    
    _updateImageError = (imgData, ctrlData, indx, ignoreFormat) => {
        let msgError = null;
        if(!imgData.imageBase64 || (!ignoreFormat && !isValidImageType(imgData.imageBase64))) {
            msgError = !imgData.imageBase64 ? "Selecione uma imagem." : "Tipo de imagem inválido.";
        }
        if(msgError || !ignoreFormat) {
            ctrlData.fields.imagens.errorMessage[indx] = msgError;
        }
        return msgError;
    }

    _updateParameterError = (imgData, ctrlData, indx) => {
        const parametro = imgData.paramModule;
        if(imgData.openModule && parametro && parametro.trim()) {
            try {
                const json = JSON.parse(parametro);
                if(!genesysUtils.typeCheck.isObject(json)) {
                    throw new Error("parâmetro deve ser um objeto e não um array ou string.")
                }
            } catch(e) { // Invalid field 'parametro' (Invalid JSON object)
                ctrlData.fields.paramModule.errorMessage[indx] = "Parâmetro, se informado, deve ser um objeto (não um array) JSON válido.";
                return ctrlData.fields.paramModule.errorMessage[indx];
            }
        }
        return null;
    }

    _validateItemAspectRatio = (listImgElems) => {
        const { newsEditItem } = this.state;
        const editItemImages = newsEditItem && newsEditItem.data && newsEditItem.data.imagens ? newsEditItem.data.imagens : null;
        if(!editItemImages || !listImgElems || listImgElems.length < 1 || listImgElems.length !== editItemImages.length) {
            return true;
        }

        let imgInfo = [];
        listImgElems.forEach((img, indx) => {
            const h = img.naturalHeight;
            const w = img.naturalWidth;
            if(!editItemImages[indx].deleted && h && w) {
                imgInfo.push({
                    height: h,
                    width: w,
                    ratio: w/h
                });
            }
        });
        const maxAspect = imgInfo.reduce((prev, curr) => (prev > curr.ratio) ? prev : curr.ratio, 0);
        const deltaAspect = imgInfo.map(info => (1-(info.ratio/maxAspect)));
        return !deltaAspect.find(delta => delta >  IMAGE_MAX_DELTA_ASPECT_RATIO);
    }

    _validateItemImageType = (itemImages) => {
        return itemImages.filter(img => !img.deleted && !isValidImageType(img.imageBase64)).length === 0;
    }

    _validateItemToSave = (listImgElems) => {
        const { newsEditItem } = this.state;
        let validationError = null;

        if(newsEditItem.data.visivel !== true && newsEditItem.data.visivel !== false) {
            validationError = "A visibilidade deve ser indicada.";
        }

        if(!this._validateItemAspectRatio(listImgElems)) {
            const maxDelta = (100*IMAGE_MAX_DELTA_ASPECT_RATIO).toLocaleString("pt-BR", {minimumFractionDigits: 0});
            validationError = `Existe uma variação superior a ${maxDelta}% entre as proporções das imagens`;
        }

        if(!genesysUtils.typeCheck.isArray(newsEditItem.data.imagens) || !newsEditItem.data.imagens.find(img => !img.deleted)) {
            validationError = "Pelo menos uma imagem deve ser adicionada.";
        } else {
            newsEditItem.data.imagens.forEach((img, indx) => {
                if(this._updateImageError(img, newsEditItem.control, indx, img.deleted)) {
                    validationError = "Verifique a(s) imagem(s) selecionada(s).";
                }
            });

            if(!validationError) {
                newsEditItem.data.imagens.forEach((img, indx) => {
                    if(this._updateParameterError(img, newsEditItem.control, indx)) {
                        validationError = "Verifique o correto preenchimento do(s) parâmetro(s) informado(s).";
                    }
                });
            }
        }

        if(validationError) {
            newsEditItem.control.erroEdicao = validationError;
            this.setState({ newsEditItem });
            return false;
        }

        return true;
    }

    componentDidUpdate(_, prevState) {
        if (this.state.currentStep===STEPS.EDIT) {
            if (!this.state.credentials) {
                this.setState(this._initialState());
                return;
            }

            if(prevState.newsEditItem && !this.state.newsEditItem) {
                this.setState({ erroProcessamento: null });
            }
        } else {
            this._focusFormEditField();
        }
    }

    componentDidMount() {
        this._initializeNewsManager();
/*
        this.setState({
            currentStep: STEPS.EDIT,
            credentials: { keepAliveTime: Date.now() }
        }, () => this._carregarDados());
*/
    }

    render() {
        const { currentStep, loginFields, erroProcessamento, credentials, newsItems, confirmationData, newsEditItem } = this.state;
        const loginEnabled = loginFields && loginFields.usuario && loginFields.usuario.value && loginFields.senha && loginFields.senha.value;
        const showError = erroProcessamento && !newsEditItem ? true : false;
        const isSaveEnabled = this._checkNewsListChanges(newsItems);

        return (
            <div className="news-manager-wrapper">
                <AppExtraDocumentHead subTitle="News Manager" robots="noindex,nofollow" />

                <div className="content">
                    <div className="header-card">
                        <AppExternalServiceHeader linkToHome={false}>
                            <h1>News Manager</h1>
                        </AppExternalServiceHeader>
                    </div>

                    { currentStep===STEPS.INITIALIZE &&
                    <AppExternalServiceInfoMessage id="msgInitNewsErrorID" className="info-error">{erroProcessamento}</AppExternalServiceInfoMessage>
                    }

                    { currentStep === STEPS.LOGIN &&
                    <div className="login-section">
                        <Form name="formMain">
                            <div className="form-wrapper">
                                <FormBuilder 
                                    className="form-login" 
                                    page={0}
                                    fields={loginFields}
                                    config={this.formConfigLogin}
                                    onChange={this._handleLoginFormUpdate}
                                    overrideFieldRender={{
                                        'input': InputField
                                    }}
                                />
                            </div>

                            <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                                {erroProcessamento}
                            </AppExternalServiceInfoMessage>

                            <div className="action-section">
                                <button type="default" disabled={!loginEnabled} className="btn-login" onClick={() => this._handleLogin()}>Login</button>
                            </div>
                        </Form>
                    </div>
                    }

                    { currentStep===STEPS.EDIT &&
                    <div className="content-section">

                        <ExternalServicesSessionLifetime
                            sessionStartTime={credentials ? credentials.keepAliveTime : null}
                            onExpire={() => this._handleSessionExpired()}
                            onRefresh={() => this._handleSessionRefresh()}
                            sessionExpireTimeMs={CREDENTIAL_EXPIRING_MS}
                            expirationWarningSec={SESSION_EXPIRATION_WARNING_SEC}
                        />

                        { confirmationData && <AppConfirmationDialog {...confirmationData} /> }

                        <NewsEditModal
                            editItem={newsEditItem}
                            checkItemChanged={this._checkItemChanged}
                            imageAccept="image/png, image/jpg, image/jpeg"
                            onEditClose={this._handleNewsEditClose}
                            onEditOk={this._handleNewsEditOk}
                            onToggleVisibility={this._handleNewsEditToggleVisibility}
                            onImageSwapOrder={this._handleNewsEditImageOrderSwap}
                            onFieldChange={this._handleNewsEditImageItemFormChange}
                            onImageAdd={this._handleNewsEditImageItemAdd}
                            onImageChange={this._handleNewsEditImageItemChange}
                            onImageDelete={this._handleNewsEditImageItemDeleteSet}
                            onLocationChange={this._handleNewsEditLocationChange}
                        />

                        <div className="section-header-wrapper">
                            <h2>Notícias:</h2>
                        </div>

                        { newsItems &&
                        <NewsEditList
                            newsList={newsItems}
                            checkItemChanged={this._checkItemChanged}
                            checkItemImageType={this._validateItemImageType}
                            onItemInsert={this._handleNewsInsert}
                            onItemEdit={this._handleNewsEdit}
                            onItemDelete={this._handleNewsDelete}
                            onToggleVisibility={this._handleNewsToggleVisibility}
                            onSetOrder={this._handleNewsOrderChange}
                            onSwapOrder={this._handleNewsOrderSwap}
                        />
                        }

                        { showError &&
                        <AppExternalServiceInfoMessage id="msgErrorID" className="info-error">
                            {erroProcessamento}
                        </AppExternalServiceInfoMessage>
                        }

                        { newsItems &&
                        <div className="action-buttons">
                            <button type="default" disabled={!isSaveEnabled} className="btn-save" onClick={() => this._handleSave()}>Salvar Alterações</button>
                        </div>
                        }

                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default NewsManager;
